import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {useState} from "react";
import Login from "./pages/Login";
import axios from "axios";
import associationOptions from "./associations";
import * as emailjs from "emailjs-com";

let options = associationOptions.map(option => ({ value: option, label: option }))

function App() {
  return (
      <>
          <Router>
              <Routes>
                  <Route path="/" element={<Home/>}></Route>
                  <Route path="/login" element={<Login/>}></Route>
              </Routes>
          </Router>
      </>
  );
}

function Home () {
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [hostNumber, setHostNumber] = useState("")
    const [inviter, setInviter] = useState("")
    const [association, setAssociation] = useState("")
    // eslint-disable-next-line no-use-before-define
    const associationOptions = options

    const capitalize = (str) => {
        if (!str) return ""; // Gestione per stringhe vuote o undefined
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }


    const resetForm = () => {
        setName("")
        setSurname("")
        setEmail("")
        setPhone("")
        setHostNumber("")
        setInviter("")
        setAssociation("")
    }

    const handleOnClickSubmit = (e) => {
        e.preventDefault();

        let data = {
            name: capitalize(name),
            surname: capitalize(surname),
            email: email,
            phone: phone,
            hostNumber: hostNumber,
            inviter: inviter,
            association: capitalize(association),
        };

        // Specifica l'ID desiderato
        let customID = name + '_' + surname + '_' + phone;

        axios.put(`https://nicolavitrani-a4783-default-rtdb.europe-west1.firebasedatabase.app/invito_opera/${customID}.json`, data)
            .then(response => {

                emailjs.send(
                    'service_7cnshw7',
                    'template_5l47o9q',
                    {
                        to_name: name,
                        to_email: email,
                        subject: 'Conferma della tua prenotazione',
                        message: `Grazie per aver confermato la tua presenza! Ti aspettiamo il 4 Dicembre al Cinetratro dell'Opera.\nTrovi la sede a questo link: https://maps.app.goo.gl/vVywT7nSkAyskDNd7`
                    },
                    'vX2-3D1Z6JAF-H0O-'
                )
                .then(mail => {
                    // console.log("mail sended", mail)
                })

                if (response.status === 200) {
                    window.Swal.fire({
                        html: "Grazie per aver confermato la tua presenza! <br/> Ti aspettiamo il 4 Dicembre al Cinetratro dell'Opera",
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonColor: "#F68B1F",
                        cancelButtonColor: "#395E14",
                        confirmButtonText: "OK!"
                    }).then(result => {
                        if (result) {
                            resetForm()
                        }
                    })
                }
            })
            .catch(error => {
                console.log("error on sending", error);
                window.Swal.fire({
                    html: "Errore nella richiesta, si prega di riprovare",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#F68B1F",
                    cancelButtonColor: "#395E14",
                    confirmButtonText: "OK!"
                }).then(result => {
                    if (result) {
                        resetForm()
                    }
                })
                // alert("Errore nella prenotazione. Si prega di riprovare");
            });
    };


    return (
        <>
            <div className="container-fluid">
                <div className="row align-items-center justify-content-center py-4 mb-4 mb-md-0">
                    <div className="col-10 col-md-7 col-lg-4 text-center">
                        <a href="https://www.bancoalimentare.it/sedi-locali/daunia-foggia" className="text-decoration-none">
                            <img src="/img/logo-15-anni.png" alt="Logo Banco Alimentare" className="img-fluid"/>
                        </a>
                    </div>
                </div>
                <div className="row align-items-center justify-content-center">
                    <div className="col-8 d-none d-md-block text-center">
                        <img src="/img/non-morirò-di-fame-locandina.jpg" alt="Immagine Banco Alimentare" className="img-fluid"/>
                    </div>
                    <div className="col-12 d-md-none">
                        <img src="/img/non-morirò-di-fame-locandina.jpg" alt="Immagine Banco Alimentare" className="img-fluid"/>
                    </div>
                </div>
                <div className="row align-items-center justify-content-center py-4 bg-primary">
                    <div className="col-11 col-md-9 col-lg-7 fw-bolder fs-1 text-white">
                        MERCOLEDÌ 4 DICEMBRE 2024 ore 20.00
                    </div>
                    <div className="col-11 col-md-9 col-lg-7  fw-bolder fs-1 text-white">
                        Cineteatro dell'Opera, Lucera
                    </div>
                </div>
                <div className="row align-items-center justify-content-center pt-4">
                    <div className="col-11 col-md-9 col-lg-7 fs-4">
                        <div className="text-secondary">
                            Saluti
                        </div>
                        <div className="text-primary">
                            <strong>Stefania Menduno</strong>, presidente Banco Alimentare della Daunia F. Vassalli
                        </div>
                        <div className="text-primary">
                            <strong>Gianluca Russo</strong>, direttore Banco Alimentare della Daunia F. Vassalli
                        </div>
                    </div>
                    <div className="col-11 col-md-9 col-lg-7 fs-4 my-4 my-md-3">
                        <div className="text-primary">
                            Proiezione del film, <strong>"Non morirò di fame"</strong>
                        </div>
                    </div>
                    <div className="col-11 col-md-9 col-lg-7 fs-4 mb-4 mb-md-3">
                        <div className="text-secondary">
                            Intervengono
                        </div>
                        <div className="text-primary">
                            <strong>Don Rocco Coppolella</strong>, direttore Caritas Lucera
                        </div>
                        <div className="text-primary">
                            <strong>Peppe Zullo</strong>, chef
                        </div>
                    </div>
                    <div className="col-11 col-md-9 col-lg-7 mb-4 mb-md-3 fs-4">
                        <div className="text-secondary">
                            Ingresso libero
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center mb-4 mb-md-5">
                    <div className="col-8 col-md-5 col-lg-3 text-center text-md-end">
                        <a href="https://www.bancoalimentare.it/sedi-locali/daunia-foggia" className="text-decoration-none">
                            <img src="/img/logo-15-anni.png" alt="Logo dei 15 anni del Banco Alimentare della Daunia" className="img-fluid"/>
                            <div className="text-secondary text-end d-flex align-items-center justify-content-end fs-5">
                                <strong>#bancoadaunia</strong><img src="/img/social.png" alt="Icone social" className="img-fluid ms-2" style={{height: '30px'}}/>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="row align-items-start justify-content-around pb-5">
                    <div className="col-12 col-md-10 col-lg-6 px-4 mt-5 mt-md-0 bg-light py-5 px-3 rounded shadow-sm">
                        <div className="col-12 text-center">
                            <h2 className="text-primary fw-bolder">
                                Partecipa alla proiezione del film "Non morirò di fame"
                            </h2>
                        </div>
                        <div className="col-12 text-center pt-4 pb-2 fw-bolder mb-5">
                            Ingresso libero. <br/>
                            Per ragioni organizzative ti chiediamo di confermare la presenza compilando i seguenti campi.
                        </div>
                        {/*<div className="col-12 text-center fw-bolder mb-5">*/}
                        {/*    Compila i seguenti campi*/}
                        {/*</div>*/}

                        <form onSubmit={(e) => handleOnClickSubmit(e)}>
                            <div className="mb-3">
                                <label className="form-label">Nome</label>
                                <input onChange={(e) => setName(e.target.value)} value={name} type="text" className="form-control" required={true}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Cognome</label>
                                <input onChange={(e) => setSurname(e.target.value)} value={surname} type="text" className="form-control" required={true}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" className="form-control" required={true}/>
                            </div>
                            <div className="d-flex mb-3">
                                <div className="col-6 pe-2">
                                    <label className="form-label">Telefono</label>
                                    <input onChange={(e) => setPhone(e.target.value)} value={phone} type="tel" className="form-control" required={true}/>
                                </div>
                                <div className="col-6 ps-2">
                                    <div className="mb-3">
                                        <label className="form-label">Numero di persone</label>
                                        <select onChange={(e) => setHostNumber(e.target.value)} value={hostNumber} className="form-select" aria-label="Seleziona numero di persone" required={true}>
                                            <option value="">Seleziona opzione</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">0</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                            <option value="24">24</option>
                                            <option value="25">25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                            <option value="28">28</option>
                                            <option value="29">29</option>
                                            <option value="30">30</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Azienda/Ente/Altro</label>
                                <input onChange={(e) => setAssociation(e.target.value)} value={association} type="text" className="form-control" required={false}></input>
                            </div>
                            <div className="mt-5">
                                <button type="submit" className="btn btn-primary">Invia</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row align-items-start justify-content-around py-3">
                    <div className="col-12 col-md-6 col-lg-5 px-4">
                        <div className="col-12 text-center pt-5 px-3">
                            <h2 className="text-primary fw-bolder">
                                Raggiungi il Cineteatro dell'Opera
                            </h2>
                        </div>
                        <div className="col-12 text-center py-4 fw-bolder mb-5">
                            La proiezione avrà luogo presso la sede del Cineteatro dell'Opera, in via G. Bruno 5, a Lucera.
                        </div>
                        <div className="col-12 map-container">
                            <iframe title="Mappa della sede del Cinema Teatro dell'opera di Lucera" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2987.8254166339425!2d15.325187711208121!3d41.50805797116494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1339e1ef66ea552f%3A0xc7e4c6375ab1c7ef!2sCinema%20Theater%20Opera!5e0!3m2!1sen!2sit!4v1732310591333!5m2!1sen!2sit" width="100%" height="400" allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="col-12 text-center my-4">
                            <a href="https://maps.app.goo.gl/vVywT7nSkAyskDNd7" className="text-decoration-none">
                                <button className="btn btn-secondary">
                                    Raggiungi il teatro
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mt-5 pb-3">
                    <div className="col-12 text-center py-2 fw-bolder">
                        <a href="/login" className="text-decoration-none text-secondary">
                            Area riservata
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default App;
